import { Component, OnInit, Input, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AmlineService } from './amline.service';
import { ActivatedRoute, Router } from "@angular/router";
import { UserInfoService } from '../../services/user-info.service';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-amline',
  templateUrl: './amline.component.html',
  styleUrls: ['./amline.component.css']
})
export class AmlineComponent implements OnInit {
  selectedIndex: number = 0;
  @Input() quote;
  full_details_tab = false;
  statement_of_facts_tab = false;
  payment_details = false;
  submission = false;
  staticData;
  modelObj = new Amline();
  url_id = this.route.snapshot.params.id;
  // CalculationDetails = new CalculationsDetails();


  constructor(private as: AmlineService, private route: ActivatedRoute, private sessionSt: LocalStorageService,
    private userService: UserInfoService, private cdr: ChangeDetectorRef, private router: Router) { }


  ngOnInit(): void {
    this.userService.getUserDetails();
    this.getAccessToken();
    this.getAmlineData();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  authDetails: string = '';
  getAccessToken() {
    let data = {
      'client_id': environment.AMLINE_CLIENT_ID,
      'client_secret': environment.AMLINE_CLIENT_SECRET,
      'grant_type': environment.AMLINE_GRANT_TYPE,
      'scope': environment.AMLINE_SCOPE,
    }
    this.as.getAccessToken(data)
      .subscribe(
        (response: any) => {
          if (response.access_token) {
            this.authDetails = response;
            this.getStaticData(response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // GET STATIC DATA
  getStaticData(response) {
    this.as.getStaticData(response)
      .subscribe(
        (response: any) => {
          if (response.meta) {
            this.staticData = response.meta;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // GET AMLINE DATA
  apiModelObj;
  getAmlineData(id = '') {
    if (this.route.snapshot.params.id) {
      let quote_id = this.route.snapshot.params.id;
      let temp_modelObj = this.modelObj;
      if(id) {
        quote_id = id;
      }
      this.as.getAmlineData(quote_id)
        .subscribe(
          (response: any) => {
            if (response.result) {
              response.result.quote_data.quote_versions[0].amlin_data.data.attributes.application.vehicles.forEach((res) => {
                let drivers = [];
                if (res.drivers && res.drivers.length > 0) {
                  res.drivers.forEach((d) => {
                    drivers.push(d);
                  })
                  res.drivers = drivers;
                }
  
              })

              this.apiModelObj = response.result.quote_data.quote_versions[0];
              this.modelObj = response.result.quote_data.quote_versions[0];
              
              if(response.result.quote_data.quote_versions[0].amlin_status == "referred") {
                this.router.navigate(['/amline/quote-details/', this.route.snapshot.params.id]);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  modelObjUpdated(data) {
    this.modelObj = null;
    this.modelObj = data;
    this.getAmlineData(data.id);
    this.selectedIndex += 1;
  }

  isFleetActivated: boolean = false;
  is_fleet(event) {
    this.isFleetActivated = event;
  }



  tabChanged(event) {
    //   var a = this.quoteDetailsForChildTemplates;
    //   this.quoteDetailsForChildTemplates = null;
    //   this.quoteDetailsForChildTemplates = a;
    //   if (event.index == 0) {
    //     this.showHideAEIOSection();
    //     if (this.quoteDetailsForChildTemplates.vet_fee_sum_insured_id == '713b6924-5b80-4c76-a469-f767594bc7d1') {
    //       this.quoteDetailsForChildTemplates.full_quote_section = true;
    //       this.quoteDetailsForChildTemplates.aeio_quote_section = false;
    //     }
    //     // this.quoteDetailsForChildTemplates.aeio_quote_section = true;
    //     // this.quoteDetailsForChildTemplates.full_quote_section = true;
    //     this.quoteDetailsForChildTemplates.indicativePage = true;
    //   } else {
    //     if (this.quoteDetailsForChildTemplates.is_aeio == 1) {
    //       this.quoteDetailsForChildTemplates.aeio_quote_section = true;
    //       this.quoteDetailsForChildTemplates.full_quote_section = false;
    //     } else {
    //       this.quoteDetailsForChildTemplates.aeio_quote_section = false;
    //       this.quoteDetailsForChildTemplates.full_quote_section = true;
    //     }
    //     this.quoteDetailsForChildTemplates.indicativePage = false;
  }


  // }

  // activateFullDetailsTab() {
  //   this.full_details_tab = true;
  //   this.quoteDetailsForChildTemplates.indicativePage = false;
  //   this.selectedIndex += 1;
  // }

  // jumpToIndicativeQuoteDetilsTab() {
  //   this.quoteDetailsForChildTemplates.indicativePage = true;
  //   this.selectedIndex = 0;
  // }
  // jumpToStatementOfFactTab() {
  //   this.statement_of_facts_tab = true;
  //   this.selectedIndex = 2;
  // }
  // jumpToFullQuoteDetilsTab() {
  //   this.full_details_tab = true;
  //   this.selectedIndex = 1;
  // }
  // jumpToPayment() {
  //   this.payment_details = true;
  //   this.selectedIndex = 3;
  // }
  // jumpToUnderWritingSubmissionTab() {
  //   this.submission = true;
  //   this.selectedIndex = 4;
  // }

}
