import { Component, OnInit, Input } from '@angular/core';
import { QuoteService } from 'src/app/quote/quote.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-am-financials',
  templateUrl: './am-financials.component.html',
  styleUrls: ['./am-financials.component.css']
})
export class AmFinancialsComponent implements OnInit {
  premium: any = '{"referred": null,"ipt": 0,"gross": 0,"breakdown_ipt": 0,"breakdown_gross": 0,"total_amount": 0,"gross_premium":0,"vehicle_premiums": {},"breakdown_premiums": {},"vehicle_excesses": {},"driver_excesses": [ ],"compulsory_excess": 0}';
  vehicle_premiums_key: string = '';
  vehicle_premiums_value: number = 0;
  gross_premium: number = 0;
  commission: number = 0;
  total_amount: number = 0;
  peliwica_fee: number = 0;
  broker_admin_fee: number = 0;
  UK_HB_INSURANCE_COMPANY_ID = '';
  quote;
  @Input() set quoteDetails(value: any) {
    if (value) {
      this.quote = value;
      this.premium = value.premium;
      this.commission = value.application.commission;
      this.peliwica_fee = value.peliwica_fee;
      this.broker_admin_fee = value.broker_admin_fee;
      if (value.premium) {
        this.gross_premium = Number(value.premium.gross);
        if (value.is_send_email == 1) {
          this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(this.broker_admin_fee);
        } else {
          this.total_amount = this.gross_premium + Number(this.peliwica_fee);
        }

      }
      if (value.premium && value.premium.vehicle_premiums) {
        this.vehicle_premiums_key = Object.keys(value.premium.vehicle_premiums)[0];
        this.vehicle_premiums_value = Number(Object.values(value.premium.vehicle_premiums)[0]);

        if (Object.values(value.premium.vehicle_premiums)[0]) {

          //this.gross_premium = Number((value.premium.gross));
          //  this.gross_premium = Number(Object.values(value.premium.vehicle_premiums)[0]) + Number(value.premium.ipt);

          //   this.gross_premium = Number(Object.values(value.premium.vehicle_premiums)[0]) + Number(value.premium.ipt);
          //  this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(value.premium.breakdown_gross);
          // this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(value.premium.breakdown_gross) ;

          // this.gross_premium = Number((value.premium.gross));

          //   this.gross_premium = Number(Object.values(value.premium.vehicle_premiums)[0]) + Number(value.premium.ipt);
          //  this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(value.premium.breakdown_gross);



        }
      }
    }
  }
  constructor(private quoteService: QuoteService, private sessionSt: LocalStorageService) { }

  ngOnInit(): void {
    this.UK_HB_INSURANCE_COMPANY_ID = environment.UK_HB_INSURANCE_COMPANY_ID;
  }

  /*
* Get Peliwica Fee
*/

  getPeliwicaPremium() {
    this.quoteService.getLiveStockBrokerCommission(this.sessionSt.retrieve('userInfo').company_id, environment.AMLINE_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.peliwica_fee = response.result.broker_admin_fee;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

}
