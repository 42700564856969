<div class="card pl-0 pr-0">
    <div class="card-body">
        <div class="sidebar">
            <ul class="list-unstyled">
                <!--
                    User type 
                    2 --- Peliwica
                    3 --- Broker
                    4 --- Insurer
                -->
                <li><a routerLink="/dashboard"><i class="mdi mdi-view-dashboard"></i> Dashboard</a></li>
                <li *ngIf="UserType == 2"><a routerLink="/company"><i class=" mdi mdi-factory "></i> Company</a></li>
                <li *ngIf="UserType == 2"><a routerLink="/affinity-list"><i class=" mdi mdi-factory "></i> Affinity</a></li>
                <li *ngIf="isBroker && !isStaff"><a [routerLink]="['/user-list',userInfo.company_id]"><i
                            class="mdi mdi-account-multiple-outline"></i> Users</a></li>
                <li><a routerLink="/policy-list"><i class="mdi mdi-receipt"></i> Policy List</a></li>
                
                <li><a (click)="submenu($event)" class="submenu" id="quote"><i class="mdi mdi-message-text-outline"></i>
                        Quote</a>
                    <ul class="list-unstyled submenu_quote submenu_items">
                        <li><a routerLink="/quote-list"><i class="mdi mdi-message-text-outline"></i> Quote List</a></li>
                        <!-- <li [hidden]="UserType != 4" *ngFor="let links of companyProducts; let i = index;">
                            <a [routerLink]="['/pre-qualifier',(i+1)]"><i class="mdi mdi-message-text-outline"></i> {{ links. }}</a>
                        </li> -->
                        <li *ngIf="isEquine && UserType != 4 && isAdmin"><a routerLink="/pre-qualifier/1/"><i class="mdi mdi-message-text-outline"></i> Equine AXA</a></li>
                        <li *ngIf="isAlpaca && UserType != 4"><a [routerLink]="['/pre-qualifier/2']"><i
                                    class="mdi mdi-message-text-outline"></i> Alpaca Quote</a></li>
                        <li *ngIf="isLivestock && UserType != 4"><a [routerLink]="['/pre-qualifier/3']"><i class="mdi mdi-message-text-outline"></i>
                                Livestock</a></li>
                        <li *ngIf="isAllianz && UserType != 4"><a [routerLink]="['/pre-qualifier/4']"><i class="mdi mdi-message-text-outline"></i>
                                Equine Bastion</a></li>
                        <li *ngIf="isAJG && UserType != 4"><a [routerLink]="['/pre-qualifier/5']"><i class="mdi mdi-message-text-outline"></i>
                            Equine MIICL</a></li>
                        <li *ngIf="isAmline && UserType != 4"><a [routerLink]="['/pre-qualifier/6']"><i class="mdi mdi-message-text-outline"></i>
                            MS Amlin Horsebox</a></li>
                    </ul>
                </li>
                <li *ngIf="UserType == 2"><a (click)="submenu($event)" class="submenu" id="reports"><i
                            class="mdi mdi-file-excel"></i>
                        Reports</a>
                    <ul class="list-unstyled submenu_reports submenu_items">
                        <li><a routerLink="/bordereau"><i class="mdi mdi-file-excel"></i>
                                Bordereau</a></li>
                        <li><a routerLink="/live-policy-report"><i class="mdi mdi-file-excel"></i>
                                Live Policy Report</a></li>
                        <li><a routerLink="/lapse-report"><i class="mdi mdi-file-excel"></i>
                                Lapse Report</a></li>
                        <li><a routerLink="/customer-report"><i class="mdi mdi-file-excel"></i>
                            Customer Report</a></li>
                        <li *ngIf="isAmline"><a routerLink="/affinity-report"><i class="mdi mdi-file-excel"></i>
                            Affinity</a></li>
                        <li><a routerLink="/amlin-payment-status-import"><i class="mdi mdi-receipt"></i> Broker Statement </a></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>