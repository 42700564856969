import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, AbstractControl, FormGroup, FormArray } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { AmlineService } from '../amline.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css', '../../quote.component.css']
})
export class DriversComponent implements OnInit {
  @Input() staticData;
  @Input() authDetails;
  @Input() selectedIndex;
  frmFields: any;
  submitted = false;
  @Input() modelObj;
  @Input() apiModelObj;
  @Output() modelObjUpdated = new EventEmitter<Amline>();

  constructor(private fb: FormBuilder, private modalService: NgbModal, private as: AmlineService, private cdr: ChangeDetectorRef,
    private route: ActivatedRoute, private _snackBar: MatSnackBar) { }

  maxDOB: any = '';
  ngOnInit(): void {
    this.frmFields = this.fb.group({
      driver_conviction_declaration: ['', Validators.required],
      driver_claim_declaration: ['', Validators.required],
      drivers: this.fb.array([]),
    });
    let current_date = new Date();
    current_date.setFullYear(new Date().getFullYear() - 16);

    this.maxDOB = current_date.toISOString().slice(0, 10);
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.cdr.detectChanges();
  }

  parentModelObj;
  showDrivers: boolean = false;
  ngOnChanges(changes: SimpleChanges) {
    this.parentModelObj = this.modelObj;
    if (this.selectedIndex == 2 || this.selectedIndex == 3) {
      if (this.modelObj.drivers && this.modelObj.drivers.length > 0) {
        this.drivers.clear();
        this.modelObj.drivers.forEach((res) => {
          let driver = this.fb.group({
            id: [res.id],
            title: [res.title],
            first_name: [res.first_name],
            last_name: [res.last_name],
            dob: [res.dob],
            licence_type: [res.licence_type],
            licence_length: [res.licence_length],
            claims: [''],
            convictions: [''],

          })
          this.drivers.push(driver);
        })
      }
      this.viewDriversSection();
    }

    if (this.apiModelObj) {
      this.modelObj = this.apiModelObj.amlin_data.data.attributes.application;
      this.modelObj.id = this.apiModelObj.amlin_data.data.id;
      this.modelObj.reference = this.apiModelObj.amlin_data.data.attributes.reference;
      this.modelObj.status = this.apiModelObj.amlin_data.data.attributes.status;
      this.modelObj.sequence_number = this.apiModelObj.sequence_number;
      this.patchValues();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  get drivers(): FormArray {
    return this.frmFields.get("drivers") as FormArray
  }

  initDrivers() {
    return this.fb.group({
      id: [''],
      title: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: [''],
      dob: ['', Validators.required],
      licence_type: ['', Validators.required],
      licence_length: ['', Validators.required],
      claims: [''],
      convictions: [''],
    })
  }

  patchValues() {
    this.frmFields.patchValue({
      driver_conviction_declaration: this.modelObj.driver_conviction_declaration,
      driver_claim_declaration: this.modelObj.driver_claim_declaration,
      drivers: this.modelObj.drivers,
    });
  }

  viewDriversSection() {
    this.showDrivers = false;
    if (this.f.driver_conviction_declaration.value || this.f.driver_claim_declaration.value) {
      this.showDrivers = true;
    } else {
      if (this.modelObj.drivers && this.modelObj.drivers.length > 0) {
        this.showDrivers = true;
      }
    }
  }

  addNewDriver() {
    this.isDriverNodeEmpty = false;
    this.drivers.push(this.initDrivers());
  }

  removeDriver(index: number) {
    this.drivers.removeAt(index);
  }

  /*
* HELP TEXT POPUP
*/
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }



  isDriverNodeEmpty: boolean = null;
  onSubmit() {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }
    let formData = this.frmFields.value;
    this.modelObj.driver_conviction_declaration = formData.driver_conviction_declaration;
    this.modelObj.driver_claim_declaration = formData.driver_claim_declaration;
    this.modelObj.drivers = formData.drivers;
    if (this.modelObj.drivers && this.modelObj.drivers.length > 0) {
      this.isDriverNodeEmpty = false;
      this.modelObj.drivers.forEach((res) => {
        res.dob = formatDate(res.dob, 'yyyy-MM-dd', 'en-US');
        res.licence_length = formatDate(res.licence_length, 'yyyy-MM-dd', 'en-US');
        // Bind Claims
        if (res.claims.claims) {
          res.claims = res.claims.claims
        }
        // Bind Claims
        if (res.convictions.convictions) {
          res.convictions = res.convictions.convictions
        }

        if (this.modelObj.vehicles) {
          this.modelObj.vehicles.forEach((res1) => {
            let vehicle_drivers = res1.drivers.filter(x => x == res.id);
            if (vehicle_drivers.length == 0) {
              res1.drivers.push(res.id);
            }
          });
        }
      })


    } else {
      if (this.modelObj.driver_conviction_declaration || this.modelObj.driver_claim_declaration) {
        this.isDriverNodeEmpty = true;
        return;
      }
    }



    // GET UNIQUE ID FOR DRIVERS
    this.as.getUniqueID(this.authDetails)
      .subscribe(
        (response: any) => {
          if (response.meta) {
            this.modelObj.drivers.forEach((res) => {
              if (!res.id) {
                res.id = response.meta.id;
              }
            })

            if (this.modelObj.type == 2) {
              this.modelObj.policy_id = null;
              this.modelObj.effective_date = null;
            }

            let data = {
              data: {
                "type": this.modelObj.type,
                "attributes": this.modelObj,
                // "id": (this.route.snapshot.params.id) ? this.route.snapshot.params.id : this.modelObj.id
                "id": this.modelObj.id,
                "broker_id": this.modelObj.broker_id,
                "sequence_number": this.modelObj.sequence_number,
              }
            };
            this.as.saveQuote(data)
              .subscribe(
                (res: any) => {
                  if (res.success) {
                    if (res.result.quote_data && res.result.quote_data.quote_versions[0].amlin_data) {
                      this.modelObj = res.result.quote_data.quote_versions[0].amlin_data.attributes.application;
                      this.modelObj.id = res.result.quote_data.quote_versions[0].amlin_data.id;
                      this.modelObj.broker_id = res.result.quote_data.quote_versions[0].broker_id;
                      this.modelObj.reference = res.result.quote_data.quote_versions[0].amlin_data.attributes.reference;
                      this.modelObj.status = res.result.quote_data.quote_versions[0].amlin_data.attributes.status;
                      this.modelObj.company_id = res.result.quote_data.quote_versions[0].company_id;
                      this.modelObj.is_send_email = res.result.quote_data.quote_versions[0].is_send_email;
                      this.modelObj.broker_commission = res.result.quote_data.quote_versions[0].financial_details.broker_commission;
                      this.modelObj.broker_admin_fee = res.result.quote_data.quote_versions[0].financial_details.broker_admin_fee;
                      this.modelObj.proposer.email = res.result.quote_data.quote_versions[0].policy_holder.email;
                      this.modelObj.proposer.phone = res.result.quote_data.quote_versions[0].policy_holder.phone;
                      this.modelObj.proposer.is_email = res.result.quote_data.quote_versions[0].policy_holder.is_email;
                      this.modelObj.proposer.is_phone = res.result.quote_data.quote_versions[0].policy_holder.is_phone;
                      this.modelObj.proposer.sms = res.result.quote_data.quote_versions[0].policy_holder.sms;
                      this.modelObj.proposer.post = res.result.quote_data.quote_versions[0].policy_holder.post;
                      if (res.result.quote_data.quote_versions[0].amlin_data.attributes.premium) {
                        this.modelObj.is_referred = res.result.quote_data.quote_versions[0].amlin_data.attributes.premium.referred;
                      }

                      this.modelObj.vehicles.forEach((res) => {
                        res.breakdown_cover = false;  // changed 06-04-2023 - need to remove btreakdown
                        let drivers = [];
                        if (res.drivers && res.drivers.length > 0) {
                          res.drivers.forEach((d) => {
                            drivers.push(d);
                          })
                          res.drivers = drivers;
                        }

                      })
                      this.modelObjUpdated.emit(this.modelObj);
                    };
                  } else {
                    this._snackBar.open(res.message, '', {
                      duration: 5000,
                      panelClass: 'text-danger'
                    });
                  }
                });
          }
        });




  }

}
