import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../../quote/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { BordereauService } from '../../../bordereau/bordereau.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-amlin-payment-status-import',
  templateUrl: './amlin-payment-status-import.component.html',
  styleUrls: ['./amlin-payment-status-import.component.css', '../../../quote/quote.component.css']
})
export class AmlinPaymentStatusImportComponent implements OnInit {

  selectedFile: File | null = null;
  errMsg: string = "";
  broker_id: string = "";
  paid_status: number = 0;
  exportSectionContainer: boolean = false;
  allProducts = [];
  brokers = [];

  constructor(private quoteService: QuoteService, private _snackBar: MatSnackBar, private bordereauService: BordereauService) { }

  ngOnInit(): void {
    this.bordereauService.getProducts()
      .subscribe(
        (response: any) => {
          if (response.success) {
            this.allProducts = response.result;
          }
        });

        this.quoteService.getBrokers(6)
        .subscribe(
          (response: any) => {
            if (response.success) {
              this.brokers = response.result;
            }
          },
          (error) => {
            console.log(error);
          }
        );
  }

  onFileChange(event: any) {
    this.selectedFile = event.target.files[0];
    this.errMsg = "";
  }


  import_payment_status() {
    this.errMsg = "";
    if (!this.selectedFile) {
      this.errMsg = "Please select file";
      return;
    }

    const formData = new FormData();
    formData.append("file", this.selectedFile);

    this.quoteService.importBrokerStatement(formData)
      .subscribe(
        (response: any) => {
          if (response.success) {
            this._snackBar.open('Broker Statement Imported.', '', {
              duration: 5000,
            });
          } else {
            this._snackBar.open('Unable to upload broker statement.', '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }

        });

  }

  /*
  * Export - display export section
  */
  start_date: string = '';
  end_date: string = '';
  product: string = '';
  exportSection() {
    this.exportSectionContainer = true;
  }
  backToImportSection() {
    this.exportSectionContainer = false;
  }

  onSubmit(f: NgForm) {
    let data: any = '';
    data = {
      start_date: formatDate(this.start_date, 'yyyy-MM-dd', 'en-US'),
      end_date: formatDate(this.end_date, 'yyyy-MM-dd', 'en-US'),
      product: this.product,
      broker_id: this.broker_id,
      paid_status: this.paid_status,
    };
    this.quoteService.exportBrokerStatement(data)
      .subscribe(
        (response: any) => {
          var blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = "broker_statement.xlsx";
          link.click();
        });
  }

}
