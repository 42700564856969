import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';

import { ModalService } from '../_modal';
import { ProductBasedConfigurationService } from './product-based-configuration.service';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { switchMap } from 'rxjs/operators';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { environment } from '../../environments/environment';
import { UserInfoService } from '../services/user-info.service';
import { GridOptions } from "ag-grid-community";
import { ProductConfigurationUpsert } from './product-based-configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { stringify } from '@angular/compiler/src/util';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { StaticDocuments } from './static_documents';


@Component({
  selector: 'app-product-based-configuration',
  templateUrl: './product-based-configuration.component.html',
  styleUrls: ['./product-based-configuration.component.css']
})
export class ProductBasedConfigurationComponent implements OnInit {

  @Input() selectedProductData;


  apiMSG;
  selectedIndex: number = 0;
  commissionTab = false;
  commissionList = true;
  brokerComission = true;
  products = [];
  selectedProducts = [];
  configurationSubTabs = false;
  editMode: boolean = true;
  productList = false;
  configurationList = true;
  TINY_MCE_KEY = environment.TINY_MCE_KEY;
  companyDetails = new ProductConfigurationUpsert();
  maximum_broker_commission_and_ratained_commission: number = 0;
  maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission: number = 0;
  versionList = [];
  maxVersion: string = '';
  productName: string = '';
  commissionId: string = '';
  imagePath: string = environment.imageURL;
  logoVersionList = [];
  logoMaxVersion: string = '';
  isLogoVersionList = false;
  viewMode: boolean = true;
  loadTypeList = [];
  loadTitleList = [];
  documentLoadTypeList = [];
  bankDetailTab = false;
  bankDetailList = true;
  staticdocumentTab = false;
  staticdocumentList = true;
  bankId: string = '';


  constructor(private formBuilder: FormBuilder, private productBasedConfigurationService: ProductBasedConfigurationService,
    private modalService: ModalService, private router: Router, private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService, private userService: UserInfoService, private popUpModelService: NgbModal, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {

    // GET MAX COMMISSION LIMIT
    this.productBasedConfigurationService.maxCommission(this.selectedProductData.product_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.maximum_broker_commission_and_ratained_commission = (response as any).result.maximum_broker_commission_and_ratained_commission;
            this.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission = (response as any).result.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );

  }

  //
  ngOnChanges() {
    this.productName = this.selectedProductData.product_name;
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/

    // Get Commissions
    this.getCommissionListData();

    // Get Commission Version List
    this.getAllCommissionVersionList();

    //Get Logo List
    this.getLogoListData();

    //Get Logo Version List
    this.getAllLogoVersionList();

    //Load Type
    this.getAllLoadTypes();

    //Basic Templates
    this.getAllBasicTemplates();


    //Get All Document Load Type
    this.getAllDocumentLoadTypes();

    //Get All Load Basic Template
    this.getAllBasicDocumentTemplates();

    //Get All Document List Data
    this.getAllDocumentLoadList();

    //Get All Bank List Data
    this.getBankDetailListData();

    //Get Version List Data
    this.getAllBankDetailVersionList();

    //Get Static Document List
    this.getStaticDocumentListData();

    //Get D2C Configurations List
    this.getD2CData();

    //Get Version List Data
    this.getAllD2CVersionList();



    this.companyDetails.scheduler_doc_body = "";

    this.companyDetails.email_quote = "";
    this.companyDetails.email_type = "";

    this.companyDetails.scheduler_doc_quote_type = 0;
    this.companyDetails.scheduler_doc_type = "";

    this.companyDetails.email_subject = "";
    this.companyDetails.email_body = "";

    this.IsEmailTitleDisabled = true;
    this.IsEmailBodyDisabled = true;

    this.IsDocBodyDisabled = true;

    this.disableDocType = true;
    this.disableEmailType = true;

  }
  // Submit form
  reachedBrokerCommAndRetainedCommMaxLimit: boolean = false;
  reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit: boolean = false;
  onSubmit(f: NgForm) {
    let status = '0';
    if (f.value.status) {
      status = '1';
    }

    if (Number(this.companyDetails.broker_commission) + Number(this.companyDetails.retained_commission) > this.maximum_broker_commission_and_ratained_commission) {
      this.reachedBrokerCommAndRetainedCommMaxLimit = true;
      return false;
    }
    if (Number(this.companyDetails.broker_commission) + Number(this.companyDetails.retained_commission) + Number(this.companyDetails.pay_away_commission) > this.maximum_broker_commission_and_ratained_commission_and_peliwica_pay_away_commission) {
      this.reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit = true;
      return false;
    }

    this.reachedBrokerCommAndRetainedCommMaxLimit = false;
    this.reachedBrokerCommAndRetainedCommAndPayAwayMaxLimit = false;

    let fdata: any = f.value;
    let data = {
      id: this.commissionId,
      company_id: this.selectedProductData.company_id,
      product_id: this.selectedProductData.product_id,
      broker_commission: this.companyDetails.broker_commission,
      retained_commission: this.companyDetails.retained_commission,
      pay_away_commission: this.companyDetails.pay_away_commission,
      uw_fee: this.companyDetails.uw_fee,
      broker_admin_fee: this.companyDetails.broker_admin_fee,
      premium_loading: this.companyDetails.premium_loading,
      version: this.maxVersion,
      is_published: this.companyDetails.is_published
    };

    // Save Commission Data 
    this.productBasedConfigurationService.saveCommissionDetails(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            if (this.editMode) {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: false
              };
              this.confirmDialogService.open(options);
            }
            else {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: true
              };
              this.confirmDialogService.open(options);
            }


            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
                //this.publishCommission((response as any).result.id,"");
                if ((response as any).result.is_published == 0) {
                  this.productBasedConfigurationService.publishCommissionDetails((response as any).result.id)
                    .subscribe(
                      (response: any) => {
                        if ((response as any).success) {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-success'
                          });
                        }
                        else {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-danger'
                          });
                        }
                      });
                }
              }
            });
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );

  }


  //Upload Logo
  onSubmitLogo(fl: NgForm) {
    let status = '0';
    if (fl.value.status) {
      status = '1';
    }

    let fdata: any = fl.value;
    let formData = new FormData();
    formData.append("logo_path", this.fileToUpload);
    formData.append("company_id", this.selectedProductData.company_id);
    formData.append("product_id", this.selectedProductData.product_id);

    // Save Logo Data 
    // Save Commission Data 
    this.productBasedConfigurationService.saveLogoDetails(formData)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: true
            };
            this.confirmDialogService.open(options);
            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                if ((response as any).result.is_published == 0) {
                  this.productBasedConfigurationService.publishLogoDetails((response as any).result.id)
                    .subscribe(
                      (response: any) => {
                        if ((response as any).success) {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-success'
                          });
                        }
                        else {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-danger'
                          });
                        }
                      });
                }
              }
            });
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );
  }



  //Grid For Product Configuration
  gridOptions: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs = [
    {
      headerName: 'Version', sortable: true, field: 'version', width: 107
    },
    {
      headerName: 'Broker Comission', sortable: true, filter: false, field: 'broker_commission', width: 140
    },
    {
      headerName: 'Retained Commission', sortable: true, filter: false, field: 'retained_commission', width: 130
    },
    {
      headerName: 'Pay Away Commission', sortable: true, filter: false, field: 'pay_away_commission', width: 130
    },
    {
      headerName: 'Underwriting Fee', sortable: true, filter: false, field: 'uw_fee', width: 130
    },
    {
      headerName: 'Premium Loading', sortable: true, filter: false, field: 'premium_loading', width: 130
    },
    {
      headerName: 'Broker Admin Fee', sortable: true, filter: false, field: 'broker_admin_fee', width: 130
    },
    {
      headerName: 'Action', width: 100,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {
          links = '<a  title="Edit Commission Details" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    },
    {
      headerName: 'Publish', width: 100,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {

          links += '<a title="Publish" style="font-size: 18px; margin-left: 5px;"><i class="mdi mdi-check-circle-outline" data-action-type="publish" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    }

  ];

  rowData: any = [];
  selectedRow;
  isVersion = false;

  // Edit Commission Data 
  onGridRowClicked(e: any, publishModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            // Get Version List
            this.productBasedConfigurationService.getCommissionDataById(id)
              .subscribe(
                (response) => {
                  if ((response as any).success) {
                    this.companyDetails = (response as any).result;
                    this.commissionId = this.companyDetails.id;
                    this.commissionTab = true;
                    this.commissionList = false;
                    this.isVersion = true;
                    this.getAllCommissionVersionList();
                    this.editMode = true;
                  } else {
                    this.commissionTab = false;
                    this.commissionList = true;
                    console.log((response as any).message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            // this.companyDetails = e.data;
            // this.commissionTab = true;
            // this.commissionList = false;
            break;
          }
        case "publish":
          {
            this.popUpModelService.open(publishModel, { centered: true });
            this.selectedRow = this.gridOptions.api.getFocusedCell();
            this.companyDetails = e.data;
          }
      }
    }
  }


  publishCommission(id, selectedRow) {
    this.productBasedConfigurationService.publishCommissionDetails(id)
      .subscribe(
        (response: any) => {
          if ((response as any).success) {
            this.popUpModelService.dismissAll();
            this.getCommissionListData();
            //this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  /*
  * Get Uploaded Logo
  */
  fileName: string = '';
  fileToUpload: File = null;
  getUploadedFile(files) {
    this.fileToUpload = files.item(0);
    this.fileName = this.fileToUpload.name;
  }

  // NEXT BUTTON ACTION
  NextBtnAction() {
    this.selectedIndex += 1;
    this.modalService.close("successMsg");
  }

  // Previous BUTTON ACTION
  PreviousBtnAction() {
    this.selectedIndex = this.selectedIndex - 1;
  }

  ShowCommissionTab() {
    this.commissionTab = true;
    this.commissionList = false;
    this.isVersion = false;
    this.companyDetails.broker_commission = 0;
    this.companyDetails.retained_commission = 0;
    this.companyDetails.pay_away_commission = 0;
    this.companyDetails.uw_fee = 0;
    this.companyDetails.premium_loading = 0;
    this.companyDetails.broker_admin_fee = 0;
    this.commissionId = '';
    this.editMode = false;
  }

  BackToCommissionList() {
    this.commissionTab = false;
    this.commissionList = true;
    this.getCommissionListData();
  }

  getCommissionListData() {
    this.productBasedConfigurationService.getCommissions(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.selectedProductData.isButtonClicked = false;
            this.rowData = (response as any).result;
            this.commissionTab = false;
            this.commissionList = true;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Logo Tab Starts 
  logoTab = false;
  logoList = true;
  ShowLogoTab() {
    this.logoTab = true;
    this.logoList = false;
    this.viewMode = false;
    this.isLogoVersionList = false;
    this.companyDetails.logo_path = '';
    this.fileToUpload = File = null;
    this.fileName = '';
  }
  BackToLogoList() {
    this.logoTab = false;
    this.logoList = true;
    this.getLogoListData();
  }


  getLogoListData() {
    this.productBasedConfigurationService.getLogoList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.selectedProductData.isButtonClicked = false;
            this.logoData = (response as any).result;
            this.logoTab = false;
            this.logoList = true;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }



  //Grid For Logo Configuration
  gridOptions1: GridOptions = {
    onGridReady: () => {
      this.gridOptions1.api.sizeColumnsToFit();
    }
  };
  columnDefs1 = [
    {
      headerName: 'Version', sortable: true, field: 'version', width: 150
    },
    {
      headerName: 'Action', width: 150,
      cellRenderer: function (params) {
        let links = '<a  title="View Details" style="font-size: 18px; color: #464dee !important;"><i class="mdi mdi-eye" data-action-type="view" id="' + params.data.id + '"></i></a>';

        return links;
      }
    },
    {
      headerName: 'Publish', width: 100,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {

          links += '<a title="Publish" style="font-size: 18px; margin-left: 5px; color: #FFFFFF !important;"><i class="mdi mdi-check-circle-outline" data-action-type="publish" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    }

  ];

  logoData: any = [];

  publishLogo(id, selectedRow) {
    this.productBasedConfigurationService.publishLogoDetails(id)
      .subscribe(
        (response: any) => {
          if ((response as any).success) {
            this.popUpModelService.dismissAll();
            //this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
            this.getLogoListData();
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }

  // Edit Commission Data 
  onLogoGridRowClicked(e: any, publishLogoModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "view":
          {
            // Get Version List
            this.productBasedConfigurationService.getLogoDataById(id)
              .subscribe(
                (response) => {
                  if ((response as any).success) {
                    //this.companyDetails= (response as any).result;
                    this.companyDetails.logo_path = (response as any).result.logo_path;
                    this.companyDetails.logoversion = (response as any).result.version;
                    this.logoTab = true;
                    this.logoList = false;
                    this.isLogoVersionList = true;
                    this.viewMode = true;
                    this.getAllLogoVersionList();
                  } else {
                    this.logoTab = false;
                    this.logoList = true;
                    this.viewMode = false;
                    console.log((response as any).message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            break;
          }
        case "publish":
          {
            this.popUpModelService.open(publishLogoModel, { centered: true });
            this.selectedRow = this.gridOptions.api.getFocusedCell();
            this.companyDetails = e.data;
          }
      }
    }
  }

  //Common Funtions 

  //Commission Version List
  getAllCommissionVersionList() {
    this.productBasedConfigurationService.getVersionList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.versionList = (response as any).result;
            this.versionList.forEach((res) => {
              this.maxVersion = res.version;
            })
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Logo All Version List
  getAllLogoVersionList() {
    this.productBasedConfigurationService.getLogoVersionList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.logoVersionList = (response as any).result;
            this.logoVersionList.forEach((res) => {
              this.logoMaxVersion = res.version;
            })
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Bank Detail Version List
  getAllBankDetailVersionList() {
    this.productBasedConfigurationService.getBankDetailVersionList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.versionList = (response as any).result;
            this.versionList.forEach((res) => {
              this.maxVersion = res.version;
            })
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  staticDocumentData: any = [];
  getStaticDocumentListData() {
    this.productBasedConfigurationService.getStaticDocumentList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.selectedProductData.isButtonClicked = false;
            this.staticDocumentData = (response as any).result;
            this.staticdocumentTab = false;
            this.staticdocumentList = true;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //Grid For Product Configuration
  gridOptions5: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs5 = [
    {
      headerName: 'Version', sortable: true, field: 'version_number', width: 340
    },
    {
      headerName: 'Title', sortable: true, filter: false, field: 'title', width: 330
    },
    {
      headerName: 'Document', sortable: true, filter: false, field: 'file_name', width: 295
    },
  ];


  //Email Tab Starts 
  emailTab = false;
  emailList = true;
  ShowEmailTab() {
    this.emailTab = true;
    this.emailList = false;
    this.viewMode = false;
    this.companyDetails.email_body = "";
    this.companyDetails.email_subject = "";
    this.companyDetails.email_type = "";
    this.companyDetails.email_quote = "";
    this.IsEmailBodyDisabled = true;
    this.IsEmailTitleDisabled = true;
    this.disableEmailType = true;
  }
  BackToEmailList() {
    this.emailTab = false;
    this.emailList = true;
  }


  //Grid For Email Configuration
  gridOptions2: GridOptions = {
    onGridReady: () => {
      this.gridOptions1.api.sizeColumnsToFit();
    }
  };
  columnDefs2 = [
    {
      headerName: 'Version', sortable: true, field: 'version', width: 150
    },
    {
      headerName: 'Email', sortable: true, field: 'email', width: 250
    },
    {
      headerName: 'Content', sortable: true, field: 'content', width: 267
    },
    {
      headerName: 'Action', width: 150,
      cellRenderer: function (params) {
        let links = '<a  title="View Details" style="font-size: 18px; color: #464dee !important;"><i class="mdi mdi-eye" data-action-type="view" id="' + params.data.id + '"></i></a>';

        return links;
      }
    },
    {
      headerName: 'Publish', width: 150,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {

          links += '<a title="Publish" style="font-size: 18px; margin-left: 5px; color: #FFFFFF !important;"><i class="mdi mdi-check-circle-outline" data-action-type="publish" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    }

  ];

  emailData: any = [];

  IsEmailBodyDisabled: boolean = true;
  IsEmailTitleDisabled: boolean = true;
  disableEmailType: boolean = true;
  emailTypeDropDown: any = [];
  docTypeDropDown: any = [];
  onEmailQuoteSelected(e) {
    this.companyDetails.email_body = "";
    this.companyDetails.email_subject = "";
    this.companyDetails.email_type = "";
    this.IsEmailTitleDisabled = true;
    this.IsEmailBodyDisabled = true;
    this.disableEmailType = true;
    // if(e.value > 0){
    //   this.IsEmailTitleDisabled=false;
    //   this.disableEmailType = false;
    // }

    this.getAllLoadTitles(e.value);
  }

  onEmailSelection(e) {
    this.IsEmailBodyDisabled = true;
    this.companyDetails.email_body = "";
    this.companyDetails.email_subject = "";
    this.IsEmailBodyDisabled = true;
    this.IsEmailTitleDisabled = true;
    if (e.value != null) {
      this.IsEmailBodyDisabled = false;
      this.IsEmailTitleDisabled = false;
    }
    this.getEmailTemplateById(e.value);
  }

  //Document Tab
  //Email Tab Starts 
  documentTab = false;
  documentList = true;
  ShowDocumentTab() {
    this.documentTab = true;
    this.documentList = false;
    this.viewMode = false;
    this.companyDetails.scheduler_doc_quote_type = 0;
    this.companyDetails.scheduler_doc_type = "";
    this.IsDocBodyDisabled = true;
    this.IsDocTitleDisabled = true;
    this.companyDetails.scheduler_doc_body = "";
    this.disableDocType = true;

  }
  BackToDocumentList() {
    this.documentTab = false;
    this.documentList = true;
  }


  //Grid For Document Configuration
  gridOptions3: GridOptions = {
    onGridReady: () => {
      this.gridOptions1.api.sizeColumnsToFit();
    }
  };
  columnDefs3 = [
    {
      headerName: 'Title', sortable: true, field: 'title', width: 837
    },
    {
      headerName: 'Action', width: 130,
      cellRenderer: function (params) {
        let links = '<a  title="Edit Document Details" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';

        return links;
      }
    },

  ];

  documentData: any = [];




  IsDocBodyDisabled: boolean = true;
  IsDocTitleDisabled: boolean = true;
  disableDocType: boolean = true;
  onDocQuoteSelected(e) {
    this.companyDetails.scheduler_doc_body = "";
    this.companyDetails.scheduler_doc_type = "";

    if (e.value != null) {
      this.disableDocType = false;
      this.getAllDocumentLoadTitles(e.value);
    }
    else {
      this.companyDetails.scheduler_doc_quote_type = 0;
      this.companyDetails.scheduler_doc_type = "";
      this.companyDetails.scheduler_doc_body = "";
      this.disableDocType = true;
      this.IsDocBodyDisabled = true;
    }
  }
  onDocSelection(e) {
    //alert(e.value.value);
    this.companyDetails.scheduler_doc_body = "";
    this.IsDocBodyDisabled = true;
    if (e.value != null) {
      this.IsDocBodyDisabled = false;
      this.getDocumentTemplateById(e.value);
    }
  }






  //On Email Submit    
  onSubmitEmail(fe: NgForm) {
    let data = {
      id: this.companyDetails.email_type,
      company_id: this.selectedProductData.company_id,
      product_id: this.selectedProductData.product_id,
      content: this.companyDetails.email_body,
      title: this.companyDetails.email_title,
      subject: this.companyDetails.email_subject,
    };

    // Save Commission Data 
    this.productBasedConfigurationService.saveEmailDetails(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );
  }

  //All Basic Template
  getAllBasicTemplates() {
    this.productBasedConfigurationService.getMailLoadBasic(this.selectedProductData.company_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.logoVersionList = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //All Load Types
  getAllLoadTypes() {
    this.productBasedConfigurationService.getMailLoadType()
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.loadTypeList = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  //All Load Titles
  getAllLoadTitles(type) {
    this.productBasedConfigurationService.getMailLoadTitle(this.selectedProductData.company_id, this.selectedProductData.product_id, type)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.loadTitleList = (response as any).result;
            if (this.loadTitleList.length > 0) {
              this.IsEmailTitleDisabled = false;
              this.disableEmailType = false;
            }
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getEmailTemplateById(temp_id) {
    this.productBasedConfigurationService.getMailLoadTemplateById(temp_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            //this.loadTitleList= (response as any).result;
            this.companyDetails.email_body = (response as any).result.content;
            this.companyDetails.email_subject = (response as any).result.subject;
            this.companyDetails.email_title = (response as any).result.title;


          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  //All Basic Template
  getAllBasicDocumentTemplates() {
    this.productBasedConfigurationService.getDocumentLoadBasic(this.selectedProductData.company_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.logoVersionList = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  //All Load Types
  getAllDocumentLoadTypes() {
    this.productBasedConfigurationService.getDocumentLoadType()
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.documentLoadTypeList = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  documentLoadTitleList = [];
  //All Load Titles
  getAllDocumentLoadTitles(type) {
    this.productBasedConfigurationService.getDocumentLoadTitle(this.selectedProductData.company_id, this.selectedProductData.product_id, type)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.documentLoadTitleList = (response as any).result;
            if (this.documentLoadTitleList.length > 0) {
              this.disableDocType = false;
            }
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getDocumentTemplateById(temp_id) {
    this.productBasedConfigurationService.getDocumentLoadTemplateById(temp_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            //this.loadTitleList= (response as any).result;
            this.companyDetails.scheduler_doc_body = (response as any).result.content;
            this.companyDetails.scheduler_doc_subject = (response as any).result.subject;

          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmitDocument(fd: NgForm) {
    let data = {
      id: this.companyDetails.scheduler_doc_type,
      company_id: this.selectedProductData.company_id,
      product_id: this.selectedProductData.product_id,
      content: this.companyDetails.scheduler_doc_body,
      subject: this.companyDetails.scheduler_doc_subject
    };

    // Save Commission Data 
    this.productBasedConfigurationService.saveDocumentDetails(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );
  }
  //All Load Titles
  getAllDocumentLoadList() {
    this.productBasedConfigurationService.getDocumentLoadList(this.selectedProductData.company_id, this.selectedProductData.product_id)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.documentData = (response as any).result;
            if (this.documentLoadTitleList.length > 0) {
              this.disableDocType = false;
            }
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // Edit Document Data 
  pdf_template_id: string = '';
  onDocumentGridRowClicked(e: any) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            // Get Version List
            this.productBasedConfigurationService.getDocumentLoadTemplateById(id)
              .subscribe(
                (response) => {
                  if ((response as any).success) {
                    //this.companyDetails= (response as any).result;
                    this.companyDetails.scheduler_doc_type = (response as any).result.id;
                    this.companyDetails.scheduler_doc_quote_type = ((response as any).result.is_policy).toString();
                    this.companyDetails.scheduler_doc_body = (response as any).result.content;
                    this.companyDetails.scheduler_doc_subject = (response as any).result.subject;
                    this.getAllDocumentLoadTitles(this.companyDetails.scheduler_doc_quote_type);
                    this.documentTab = true;
                    this.documentList = false;
                    //this.editMode =true;
                    this.disableDocType = false;
                    this.IsDocBodyDisabled = false;
                  } else {
                    this.documentTab = false;
                    this.documentList = true;
                    console.log((response as any).message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            break;
          }
      }
    }
  }
  ShowBankDetailTab() {
    this.bankDetailTab = true;
    this.bankDetailList = false;
    this.isVersion = false;
    this.companyDetails.bank_phone = '';
    this.companyDetails.account_name = '';
    this.companyDetails.account_number = '';
    this.companyDetails.sort_code = '';
    this.bankId = '';
    this.editMode = false;
  }

  BackToBankDetailList() {
    this.bankDetailTab = false;
    this.bankDetailList = true;
    this.getBankDetailListData();
  }


  onBankDetailSubmit(fbd: NgForm) {
    let status = '0';
    if (fbd.value.status) {
      status = '1';
    }

    let fdata: any = fbd.value;
    let data = {
      id: this.bankId,
      company_id: this.selectedProductData.company_id,
      product_id: this.selectedProductData.product_id,
      phone: this.companyDetails.bank_phone,
      sort_code: this.companyDetails.sort_code,
      account_number: this.companyDetails.account_number,
      account_name: this.companyDetails.account_name,
      version_number: this.maxVersion,
      is_published: this.companyDetails.is_published
    };

    // Save Commission Data 
    this.productBasedConfigurationService.saveBankDetails(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            if (this.editMode) {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: false
              };
              this.confirmDialogService.open(options);
            }
            else {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: true
              };
              this.confirmDialogService.open(options);
            }


            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
                //this.publishCommission((response as any).result.id,"");
                if ((response as any).result.is_published == 0) {
                  this.productBasedConfigurationService.publishBankDetails((response as any).result.id)
                    .subscribe(
                      (response: any) => {
                        if ((response as any).success) {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-success'
                          });
                        }
                        else {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-danger'
                          });
                        }
                      });
                }
              }
            });
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );

  }




  bankData: any = [];
  getBankDetailListData() {
    this.productBasedConfigurationService.getBankDetailList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.selectedProductData.isButtonClicked = false;
            this.bankData = (response as any).result;
            this.bankDetailTab = false;
            this.bankDetailList = true;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  //Grid For Product Configuration
  gridOptions4: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefs4 = [
    {
      headerName: 'Version', sortable: true, field: 'version', width: 107
    },
    {
      headerName: 'Account Name', sortable: true, filter: false, field: 'account_name', width: 160
    },
    {
      headerName: 'Account Number', sortable: true, filter: false, field: 'account_number', width: 160
    },
    {
      headerName: 'Sort Code', sortable: true, filter: false, field: 'sort_code', width: 160
    },
    {
      headerName: 'Phone', sortable: true, filter: false, field: 'phone', width: 160
    },
    {
      headerName: 'Action', width: 110,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {
          links = '<a  title="Edit Bank Details" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    },
    {
      headerName: 'Publish', width: 110,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {

          links += '<a title="Publish" style="font-size: 18px; margin-left: 5px;"><i class="mdi mdi-check-circle-outline" data-action-type="publish" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    }

  ];

  // Edit Commission Data 
  onBankDetailGridRowClicked(e: any, publishBankModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            // Get Version List
            this.productBasedConfigurationService.getBankDataById(id)
              .subscribe(
                (response) => {
                  if ((response as any).success) {
                    this.companyDetails = (response as any).result;
                    this.companyDetails.bank_phone = this.companyDetails.phone;
                    this.bankId = this.companyDetails.id;
                    this.bankDetailTab = true;
                    this.bankDetailList = false;
                    this.isVersion = true;
                    this.getAllBankDetailVersionList();
                    this.editMode = true;
                  } else {
                    this.bankDetailTab = false;
                    this.bankDetailList = true;
                    console.log((response as any).message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            // this.companyDetails = e.data;
            // this.commissionTab = true;
            // this.commissionList = false;
            break;
          }
        case "publish":
          {
            this.popUpModelService.open(publishBankModel, { centered: true });
            this.selectedRow = this.gridOptions.api.getFocusedCell();
            this.companyDetails = e.data;
          }
      }
    }
  }


  publishBankDetail(id, selectedRow) {
    this.productBasedConfigurationService.publishBankDetails(id)
      .subscribe(
        (response: any) => {
          if ((response as any).success) {
            this.popUpModelService.dismissAll();
            this.getBankDetailListData();
            //this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }
  ShowStaticDocumetTab() {
    this.staticdocumentTab = true;
    this.staticdocumentList = false;
    this.isVersion = false;

    this.viewMode = false;
    this.myFiles = [];
    this.companyDetails.static_docs = []
    this.companyDetails.static_docs.push(new StaticDocuments());

  }

  BackToStaticDocumentList() {
    this.staticdocumentTab = false;
    this.staticdocumentList = true;
    this.getStaticDocumentListData();
  }

  myFiles = [];
  getUploadedDocuments(files, i) {
    this.fileToUpload = files.item(0);
    //this.fileName = this.fileToUpload.name;
    this.companyDetails.static_docs[i].file_name = this.fileToUpload.name;
    for (var j = 0; j < files.length; j++) {
      this.myFiles.push(files[j]);
    }
  }

  onSubmitStaticDocument(fsd: NgForm) {
    let status = '0';
    if (fsd.value.status) {
      status = '1';
    }

    let fdata: any = fsd.value;
    let data: any = '';
    let formData = new FormData();

    for (var i = 0; i < this.myFiles.length; i++) {
      if (this.myFiles[i]) {
        if(this.companyDetails.static_docs[i].is_vet) {
          this.companyDetails.static_docs[i].is_vet = 1;
        } else {
          this.companyDetails.static_docs[i].is_vet = 0;
        }
        if(this.companyDetails.static_docs[i].is_renew_type) {
          this.companyDetails.static_docs[i].is_renew_type = 1;
        } else {
          this.companyDetails.static_docs[i].is_renew_type = 0;
        }
        formData.append("uploaded_files_" + i, this.myFiles[i]);
        formData.append("title_" + i, this.companyDetails.static_docs[i].title);
        formData.append("is_vet_" + i, this.companyDetails.static_docs[i].is_vet);
        formData.append("is_renew_type_" + i, this.companyDetails.static_docs[i].is_renew_type);
      }
    }

    formData.append("company_id", this.selectedProductData.company_id);
    formData.append("product_id", this.selectedProductData.product_id);
    formData.append("totalcount", this.companyDetails.static_docs.length);

    data = {
      quote_data: this.companyDetails.static_docs
    };

    // Save Static Documents Data 
    this.productBasedConfigurationService.saveStaticDocumentDetails(formData)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'success',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
            //fsd.resetForm();
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }



        },
        (error) => {
          console.log(error);
        }
      );

  }

  addStaticDocuments() {
    this.companyDetails.static_docs.push(new StaticDocuments());
  }
  removeStaticDocuments(i) {
    this.myFiles[i] = "";
    this.companyDetails.static_docs.splice(i, 1);
  }

  /*
  * D2C
  */
  d2cTab: boolean = false;
  d2cList = true;
  ShowD2CTab() {
    this.companyDetails.site_name = '';
    this.companyDetails.d2c_website = '';
    this.companyDetails.d2c_phone = '';
    this.companyDetails.d2c_email = '';
    this.companyDetails.d2c_display_email = '';
    this.companyDetails.d2c_display_email_name = '';
    this.companyDetails.short_code = '';
    this.companyDetails.d2c_registration_number = '';
    this.companyDetails.fca_number = '';
    this.companyDetails.copyright_text = '';
    this.companyDetails.d2c_version = '';
    this.d2cConfigId = '';
    this.editMode = false;
    this.d2cTab = true;
    this.d2cList = false;
  }

  BackTod2cConfigList() {
    this.d2cTab = false;
    this.d2cList = true;
    this.getD2CData();
  }
  // Get List
  d2cData: any = [];
  getD2CData() {
    this.productBasedConfigurationService.getD2CList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.selectedProductData.isButtonClicked = false;
            this.d2cData = (response as any).result;
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  gridOptionsD2c: GridOptions = {
    onGridReady: () => {
      this.gridOptions.api.sizeColumnsToFit();
    }
  };
  columnDefsD2c = [
    {
      headerName: 'Version', sortable: true, field: 'version', width: 100
    },
    {
      headerName: 'Site Name', sortable: true, filter: false, field: 'site_name'
    },
    {
      headerName: 'Website', sortable: true, filter: false, field: 'website'
    },
    {
      headerName: 'Phone', sortable: true, filter: false, field: 'phone', width: 225
    },
    {
      headerName: 'Action', width: 120,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {
          links = '<a  title="Edit Configurations" style="font-size: 18px;"><i class="mdi mdi-grease-pencil" data-action-type="edit" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    },
    {
      headerName: 'Publish', width: 120,
      cellRenderer: function (params) {
        let links = '';
        if (params.data.is_published == 0) {

          links += '<a title="Publish" style="font-size: 18px; margin-left: 5px;"><i class="mdi mdi-check-circle-outline" data-action-type="publish" id="' + params.data.id + '"></i></a>';
        }

        return links;
      }
    }

  ];
  d2cConfigId: string = '';
  isd2cVersion: boolean = false;
  onD2CSubmit(fd: NgForm) {
    let status = '0';
    if (fd.value.status) {
      status = '1';
    }

    let fdata: any = fd.value;
    let data = {
      id: this.d2cConfigId,
      company_id: this.selectedProductData.company_id,
      product_id: this.selectedProductData.product_id,
      site_name: this.companyDetails.site_name,
      website: this.companyDetails.d2c_website,
      phone: this.companyDetails.d2c_phone,
      email: this.companyDetails.d2c_email,
      d2c_display_email: this.companyDetails.d2c_display_email,
      d2c_display_email_name: this.companyDetails.d2c_display_email_name,
      company_short_code: this.companyDetails.short_code,
      registration_number: this.companyDetails.d2c_registration_number,
      peliwica_fca_number: this.companyDetails.fca_number,
      copyright_text: this.companyDetails.copyright_text,
      version: this.d2cMaxVersion,
      is_published: this.companyDetails.is_published
    };

    this.productBasedConfigurationService.saveD2CData(data)
      .subscribe(
        (response) => {
          this.apiMSG = (response as any).message;
          if ((response as any).success) {
            if (this.editMode) {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: false
              };
              this.confirmDialogService.open(options);
            }
            else {
              const options = {
                title: '',
                message: (response as any).message,
                imageType: 'success',
                yesNoBtns: true
              };
              this.confirmDialogService.open(options);
            }


            this.confirmDialogService.confirmed().subscribe(confirmed => {
              if (confirmed) {
                this.selectedIndex += 1;
                //this.publishCommission((response as any).result.id,"");
                if ((response as any).result.is_published == 0) {
                  this.productBasedConfigurationService.publishD2CDetails((response as any).result.id)
                    .subscribe(
                      (response: any) => {
                        if ((response as any).success) {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-success'
                          });
                        }
                        else {
                          this._snackBar.open(response.message, '', {
                            duration: 5000,
                            panelClass: 'text-danger'
                          });
                        }
                      });
                }
              }
            });
          } else {
            const options = {
              title: '',
              message: (response as any).message,
              imageType: 'error',
              yesNoBtns: false
            };
            this.confirmDialogService.open(options);
          }
        }
      );
  }

  //D2C Version List
  d2cVersionList = [];
  d2cMaxVersion: string = '';

  getAllD2CVersionList() {
    this.productBasedConfigurationService.getD2CVersionList(this.selectedProductData.company_id, this.selectedProductData.product_id, 0)
      .subscribe(
        (response) => {
          if ((response as any).success) {
            this.d2cVersionList = (response as any).result;
            this.d2cVersionList.forEach((res) => {
              this.d2cMaxVersion = res.version;
            })
          } else {
            console.log((response as any).message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Edit D2C Data 
rowId:string = '';
  onD2CGridRowClicked(e: any, publishD2CModel) {
    if (e) {

      let id = e.event.target.getAttribute("id");
      let actionType = e.event.target.getAttribute("data-action-type");
      switch (actionType) {
        case "edit":
          {
            // Get Version List
            this.productBasedConfigurationService.getD2CById(id)
              .subscribe(
                (response) => {
                  if ((response as any).success) {
                    this.companyDetails = (response as any).result;
                    this.companyDetails.d2c_website = (response as any).result.website;
                    this.companyDetails.d2c_phone = (response as any).result.phone;
                    this.companyDetails.d2c_email = (response as any).result.email;
                    this.companyDetails.d2c_display_email = (response as any).result.d2c_display_email;
                    this.companyDetails.d2c_display_email_name = (response as any).result.d2c_display_email_name;
                    this.companyDetails.short_code = (response as any).result.company_short_code;
                    this.companyDetails.d2c_registration_number = (response as any).result.registration_number;
                    this.companyDetails.fca_number = (response as any).result.peliwica_fca_number;
                    this.d2cConfigId = (response as any).result.id;
                    this.d2cTab = true;
                    this.d2cList = false;
                    this.isVersion = true;
                    this.getAllD2CVersionList();
                    this.editMode = true;
                  } else {
                    this.d2cTab = false;
                    this.d2cList = true;
                    console.log((response as any).message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            // this.companyDetails = e.data;
            // this.commissionTab = true;
            // this.commissionList = false;
            break;
          }
        case "publish":
          {
            this.popUpModelService.open(publishD2CModel, { centered: true });
            this.selectedRow = this.gridOptions.api.getFocusedCell();
            this.companyDetails = e.data;
            this.rowId = id;
          }
      }
    }
  }

  publishD2CDetails() {
    this.productBasedConfigurationService.publishD2CDetails(this.rowId)
      .subscribe(
        (response: any) => {
          if ((response as any).success) {
            this.popUpModelService.dismissAll();
            this.getD2CData();
            //this.gridOptions.rowData.splice(selectedRow.rowIndex, 1);
            this.gridOptions.api.setRowData(this.gridOptions.rowData);
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-success'
            });
          }
          else {
            this._snackBar.open(response.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          }
        });
  }
}

