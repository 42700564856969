import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import {BordereauService } from './bordereau.service';
import { FileSaver } from 'file-saver';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-bordereau',
  templateUrl: './bordereau.component.html',
  styleUrls: ['../quote/quote.component.css'],
})
export class BordereauComponent implements OnInit {
  start_date: string = '';
  end_date: string = '';
  paid_status: number = null;
  paid_date: string = '';
  type: number = null;
  constructor(private bordereauService: BordereauService) { }

  ngOnInit(): void {
        
  }

  onSubmit(f: NgForm) {
    if (!f.form.valid) {
      console.log('Form is invalid');
      return;
    }
    let data: any = '';
    data = {
      start_date: formatDate(this.start_date, 'yyyy-MM-dd', 'en-US'),
      end_date: formatDate(this.end_date, 'yyyy-MM-dd', 'en-US'),
      type: this.type,
      paid_status: (this.paid_status)?this.paid_status:9,
      paid_date: (this.paid_date?formatDate(this.paid_date, 'yyyy-MM-dd', 'en-US'): ''),
    };
    this.bordereauService.download(data)
      .subscribe(
        (response: any) => {  
          var blob=new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download="peliwica.xlsx";
            link.click();          
        });
  }
  

}
