import { StaticDocuments } from './static_documents';
export class ProductConfigurationUpsert {
    id: string = '';
    parent_id: string = null;
    name: string = '';
    contact_name: string = '';
    registration_number: string = '';
    role_id: string = '';
    email: string = '';
    phone: string = '';
    mobile: string = '';
    website: string = '';
    business_agreement: string = '';
    terms_of_business: string = '';
    status: string = '';
    commission_percentage: number = 0;
    peliwica_pay_away_commission: number = 0;
    peliwica_ratained_commission: number = 0;
    uw_fees: number = 0;
    loading_percentage: number = 0;
    logo_path: string = '';
    scheduler_title: string = '';
    scheduler_doc_type: string = '';
    scheduler_content: string = '';
    email_body: string = '';
    scheduler_doc_body: string = '';
    email_title:string='';
    email_subject:string='';
    email_type:string='';
    email_quote="";

    // New Fields
    company_id:string='';
    product_id:string='';
    broker_commission :number=0;
    retained_commission:number=0;
    pay_away_commission:number=0;
    uw_fee:number=0;
    premium_loading:number=0;
    version:string=''; 
    is_published:number=0;
    broker_admin_fee:number=0;
    
    //Logo Fields
    logoversion:string='';
    
    //Scheduler
    scheduler_doc_quote_type:number=0;
    scheduler_doc_subject:string='';
    //Bank Detail
    bank_phone:string='';
    sort_code:string='';
    account_number:string='';
    account_name:string='';
    //static_docs: Array<object> = [new StaticDocuments()];
    static_docs: any = [new StaticDocuments()];

    // d2c Details
    site_name:string='';
    d2c_website:string='';
    d2c_phone:string='';
    d2c_email:string='';
    d2c_display_email:string='';
    d2c_display_email_name:string='';
    short_code:string='';
    d2c_registration_number:string='';
    fca_number:string='';
    copyright_text:string='';
    d2c_version:string='';
 }