<div class="card-header bg-success">
    <div class="row">
        <div class="col-md-6">
            <h3>Broker Statement
                <button class="btn btn-success btn-sm" (click)="exportSection()" *ngIf="!exportSectionContainer">Export</button>
                <button class="btn btn-success btn-sm" (click)="backToImportSection()" *ngIf="exportSectionContainer">Back</button>
            </h3>
        </div>
    </div>
</div>
<div class="card-body" *ngIf="!exportSectionContainer">
    <h4 class="text-white">Upload excel to update payment status.</h4>
    <div class="row">
        <div class="col-md-6">
            <div class="form_sections">
                <div class="row">
                    <div class="col-md-6">
                        <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls">
                        <div class="invalid-feedback">{{ errMsg }}</div>
                    </div>

                </div>
                <div class="row mt-3">
                    <div class="col-md-6">
                        <button class="btn btn-success btn-sm" (click)="import_payment_status()">Import</button>
                    </div>

                </div>
            </div>
        </div>
    </div>



</div>

<div class="card-body" *ngIf="exportSectionContainer">
    <h4>Export Broker Statement</h4>
    <div class="form_sections">
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group row">
                        <div class="col-md-4">Start Date</div>
                        <div class="col-md-8">
                            <input type="text" required name="bdr_start_date" placeholder="Start Date"
                                class="form-control form-control-sm datepicker" matInput (focus)="myDatepicker.open()"
                                [matDatepicker]="myDatepicker" [(ngModel)]="start_date" #bdr_start_date="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && bdr_start_date.invalid }">
                            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #myDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && bdr_start_date.invalid" class="invalid-feedback">
                                Start date is required.
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">End Date</div>
                        <div class="col-md-8">
                            <input type="text" required name="bdr_end_date" placeholder="End Date"
                                class="form-control form-control-sm datepicker" matInput
                                (focus)="endDateDatepicker.open()" [matDatepicker]="endDateDatepicker"
                                [(ngModel)]="end_date" #bdr_end_date="ngModel"
                                [ngClass]="{ 'is-invalid': f.submitted && bdr_end_date.invalid }">
                            <mat-datepicker-toggle [for]="endDateDatepicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDateDatepicker></mat-datepicker>
                            <div *ngIf="f.submitted && bdr_end_date.invalid" class="invalid-feedback">
                                End date is required.
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">Product</div>
                        <div class="col-md-8">
                            <mat-form-field class="w-100">
                                <mat-label>Product</mat-label>
                                <mat-select [(ngModel)]="product" class="form-control form-control-sm" name="product">
                                    <mat-option [value]="">Select Product</mat-option>
                                    <mat-option *ngFor="let prod of allProducts" [value]="prod.id">
                                        {{prod.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">Broker</div>
                        <div class="col-md-8">
                            <mat-form-field class="w-100">
                                <mat-label>Broker</mat-label>
                                <mat-select [(ngModel)]="broker_id" class="form-control form-control-sm" name="product">
                                    <mat-option [value]="">Select Broker</mat-option>
                                    <mat-option *ngFor="let broker of brokers" [value]="broker.id">
                                        {{broker.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">Paid Status</div>
                        <div class="col-md-8">
                            <mat-form-field class="w-100">
                                <mat-label>Paid Status</mat-label>
                                <mat-select [(ngModel)]="paid_status" class="form-control form-control-sm"
                                    name="af_paid_status" required
                                    #af_paid_status="ngModel" [ngClass]="{ 'is-invalid': f.submitted && af_paid_status.invalid }">
                                    <mat-option [value]="2">Not Paid</mat-option>
                                    <mat-option [value]="1">Paid</mat-option>
                                    <mat-option [value]="9">Both</mat-option>
                                </mat-select>
                                <div *ngIf="f.submitted && af_paid_status.invalid" class="invalid-feedback">
                                    Please choose an option.
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-success btn-gray">Export</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>