import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Amline } from 'src/app/model/quote/amline/amline.model';
import { AmlineService } from '../amline.service';
import { formatDate } from '@angular/common';
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { QuoteService } from '../../quote.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'ngx-webstorage';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-am-quotation',
  templateUrl: './am-quotation.component.html',
  styleUrls: ['./am-quotation.component.css', '../../quote.component.css']
})
export class AmQuotationComponent implements OnInit {
  frmFields: any;
  submitted = false;
  quote_type: number = this.route.snapshot.params.quote_type;
  @Input() authDetails;
  @Input() staticData;
  @Input() selectedIndex;
  @Input() modelObj;
  @Input() apiModelObj;
  @Output() modelObjUpdated = new EventEmitter<Amline>();
  policy_wording_doc_url = "https://s3-eu-west-1.amazonaws.com/msamlin-uat/products/documents/horsebox%20wording%20may%202021.pdf";
  breakdown_wording_doc_url = "https://s3-eu-west-1.amazonaws.com/msamlin-uat/products/documents/horsebox%20breakdown%20wording%20november%202022.pdf";
  ipid_wording_doc_url = "https://s3-eu-west-1.amazonaws.com/msamlin-uat/products/documents/horsebox%20ipid%20may%202021.pdf";
  isBroker = this.sessionSt.retrieve("is_broker");

  constructor(private fb: FormBuilder, private modalService: NgbModal, private as: AmlineService, private router: Router,
    private cdr: ChangeDetectorRef, private route: ActivatedRoute, private quoteService: QuoteService,
    private sessionSt: LocalStorageService, private _snackBar: MatSnackBar) { }

  UK_HB_INSURANCE_COMPANY_ID = '';
  ngOnInit(): void {
    this.UK_HB_INSURANCE_COMPANY_ID = environment.UK_HB_INSURANCE_COMPANY_ID;
    this.frmFields = this.fb.group({
      voluntary_excess: ['', Validators.required],
      peliwica_fee: [''],
      breakdown_cover: [''],
      registered_owner: [''],
      commission: ['20', Validators.required],
      broker_admin_fee: ['0', Validators.required],
      discount: ['0', Validators.required],
    });
  }

  parentModelObj;
  cover_period;
  reference;
  proposer_type;
  isReferred: boolean = false;
  btn_text: string;
  attention_required_text: string;
  ngOnChanges(changes: SimpleChanges) {
    if (this.apiModelObj) {
      this.modelObj = this.apiModelObj.amlin_data.data.attributes.application;
      this.modelObj.id = this.apiModelObj.amlin_data.data.id;
      this.modelObj.reference = this.apiModelObj.amlin_data.data.attributes.reference;
      this.modelObj.status = this.apiModelObj.amlin_data.data.attributes.status;
      this.modelObj.type = this.apiModelObj.type;
      this.modelObj.sequence_number = this.apiModelObj.sequence_number;
      this.modelObj.company_id = this.apiModelObj.company_id;
      this.modelObj.broker_admin_fee = this.apiModelObj.financial_details.broker_admin_fee;
      this.patchValues();
    }

    if (this.selectedIndex >= 3) {
      let inception_date;
      if (this.modelObj.amlin_data) {
        this.parentModelObj = this.modelObj.amlin_data.data;
        inception_date = this.parentModelObj.attributes.application.inception_date;
        this.reference = this.modelObj.amlin_reference;
        this.proposer_type = this.parentModelObj.attributes.application.proposer.proposer_type;
        if (this.parentModelObj.attributes.premium && this.parentModelObj.attributes.premium.referred) {
          this.isReferred = true;
        }

      } else {
        this.parentModelObj = this.modelObj;
        inception_date = this.modelObj.inception_date;
        if (this.apiModelObj) {
          this.reference = this.apiModelObj.amlin_data.data.attributes.reference;
          if (this.apiModelObj.amlin_data.data.attributes.premium && this.apiModelObj.amlin_data.data.attributes.premium.referred) {
            this.isReferred = true;
          }
        } else {
          this.reference = this.modelObj.reference;
          if (this.modelObj.is_referred) {
            this.isReferred = true;
          }
        }
        this.proposer_type = this.modelObj.proposer.proposer_type;
      }

      // if (this.isReferred) {
      //   this.calErrors = [];
      //   let msg = { "detail": "Your quote has referred, clicking save and exit will send your quote to the underwriter for review" };
      //   this.calErrors.push(msg);
      //   this.btn_text = "Save & Refer to Underwriter";
      //   this.attention_required_text = "This quote needs to be referred, please click ‘Save & Refer to Underwriter’ to proceed.";
      // } else {
      //   this.calErrors = [];
      //   this.btn_text = "Save & Exit";
      //   this.attention_required_text = "";
      // }

      let maxDate = new Date(inception_date).setFullYear(new Date(inception_date).getFullYear() + 1);
      let newDate = new Date(new Date(maxDate).getTime() - (24 * 60 * 60 * 1000));
      this.cover_period = formatDate(inception_date, 'dd-MM-yyyy', 'en-US') + ' to ' + formatDate(newDate, 'dd-MM-yyyy', 'en-US');

      this.getPeliwicaPremium();

      this.frmFields.get('broker_admin_fee').setValue(this.modelObj.broker_admin_fee);

    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.frmFields.controls;
  }

  vehicle_breakdown = [];
  comp_cover_type: string = '';
  patchValues() {
    this.frmFields.patchValue({
      voluntary_excess: this.modelObj.voluntary_excess,
      commission: 20, //this.modelObj.commission
      broker_admin_fee: this.modelObj.broker_admin_fee,
      discount: (this.modelObj.discount > 0) ? this.modelObj.discount : 0
    });

    let c_cover_type = this.modelObj.vehicles.filter(x => x.cover_type == 'Comprehensive');
    if (c_cover_type.length > 0) {
      this.comp_cover_type = 'Accidental Damage Fire & Theft';
    }

    let tf_cover_type = this.modelObj.vehicles.filter(x => x.cover_type == 'Third Party, Fire and Theft');
    if (tf_cover_type.length > 0) {
      this.comp_cover_type = 'Fire & Theft';
    }

    let t_cover_type = this.modelObj.vehicles.filter(x => x.cover_type == 'Third Party Only');
    if (t_cover_type.length > 0) {
      this.comp_cover_type = 'None';
    }

    if (this.modelObj.vehicles && this.modelObj.vehicles.length > 0) {
      this.modelObj.vehicles.forEach((res) => {
        res.own
      })
    }

  }

  /*
 * HELP TEXT POPUP
 */
  helptext = '';
  helpTextPopup(content, text) {
    this.modalService.open(content, { centered: true });
    this.helptext = text;
  }

  /*
  * Calculate Premium
  */
  ipt: number = 0;
  gross: number = 0; // Principal Amount 
  total_amount: number = 0;
  vehicle_premiums_key: string = '';
  vehicle_premiums_value: number = 0;
  gross_premium: number = 0;
  premiums: any = '{"referred": null,"ipt": 0,"gross": 0,"breakdown_ipt": 0,"breakdown_gross": 0,"total_amount": 0,"gross_premium":0,"vehicle_premiums": {},"breakdown_premiums": {},"vehicle_excesses": {},"driver_excesses": [ ],"compulsory_excess": 0}';
  breakdown_premiums_arr = [];
  calErrors: any = [];
  hasErrors: boolean = false;
  calculatePremium() {
    this.calErrors = [];
    this.bindPostData();
    let data;
    let obj = this.modelObj;
    if (this.modelObj.amlin_data) {
      obj = this.modelObj.amlin_data.data.attributes.application;
    }
    if (this.apiModelObj && this.apiModelObj.type != 3) {
      obj.policy_id = '';
      obj.effective_date = '';
    }
    obj.voluntary_excess = this.f.voluntary_excess.value;
    data = {
      data: {
        "type": "horsebox-api/quote",
        "attributes": obj
      }

    };
    this.as.calculatePremium(data, this.authDetails)
      .subscribe(
        (res: any) => {
          if (res.meta) {
            this.hasErrors = false;
            if (res.meta.referred) {
              this.calErrors = [];
              let msg = { "detail": "Your quote has referred, clicking save and exit will send your quote to the underwriter for review" };
              this.calErrors.push(msg);
              this.btn_text = "Save & Refer to Underwriter";
              this.attention_required_text = "This quote needs to be referred, please click ‘Save & Refer to Underwriter’ to proceed.";
            } else {
              this.calErrors = [];
              this.btn_text = "Save & Exit";
              this.attention_required_text = "";
            }

            if (res.meta.vehicle_premiums) {
              this.vehicle_premiums_key = Object.keys(res.meta.vehicle_premiums)[0];
              this.vehicle_premiums_value = Number(Object.values(res.meta.vehicle_premiums)[0]);
              this.gross_premium = Number(res.meta.total_gross);
              // this.gross_premium = Number(Object.values(res.meta.vehicle_premiums)[0]) + Number(res.meta.ipt);
            }
            let broker_admin_fee = (this.modelObj) ? this.modelObj.broker_admin_fee : 0;
            this.frmFields.get('broker_admin_fee').setValue(broker_admin_fee);

            if (this.modelObj && this.modelObj.is_send_email == 1) {
              this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(broker_admin_fee);
            } else {
              this.total_amount = this.gross_premium + Number(this.peliwica_fee);
            }
            // this.total_amount = this.gross_premium + Number(this.peliwica_fee) + Number(res.meta.breakdown_gross);
            this.premiums = res.meta;
            this.breakdown_premiums_arr = [];
            if (res.meta.breakdown_premiums) {
              if (res.meta.breakdown_premiums instanceof Array) {
                res.meta.breakdown_premiums.forEach((res) => {
                  let breakdown = [];
                  //breakdown[Object.keys(res.meta.breakdown_premiums)[0]] = Number(Object.values(res.meta.breakdown_premiums)[0]);
                  breakdown['registration_number'] = Object.keys(res.meta.breakdown_premiums)[0];
                  breakdown['value'] = Number(Object.values(res.meta.breakdown_premiums)[0]);

                  this.breakdown_premiums_arr.push(breakdown);
                })
              } else {
                let breakdown = [];
                breakdown['registration_number'] = Object.keys(res.meta.breakdown_premiums)[0];
                breakdown['value'] = Number(Object.values(res.meta.breakdown_premiums)[0]);
                this.breakdown_premiums_arr.push(breakdown);
                //this.breakdown_premiums_arr[Object.keys(res.meta.breakdown_premiums)[0]] = Number(Object.values(res.meta.breakdown_premiums)[0]);
              }



              this.breakdown_premiums_arr.forEach((res) => {
                this.modelObj.vehicles.forEach((res1) => {
                  if (res1.registration_number == res.registration_number) {
                    res.is_checked = res1.breakdown_cover;
                  }
                })
              })
            }
          }
        },
        err => {
          this.hasErrors = true;
          this.calErrors = [];
          if (err.error && err.error.errors) {
            this.calErrors = err.error.errors;
          }
          let cals = {
            "referred": null,
            "ipt": 0,
            "gross": 0,
            "breakdown_ipt": 0,
            "breakdown_gross": 0,
            "total_amount": 0,
            "gross_premium": 0,
            "vehicle_premiums": {},
            "breakdown_premiums": {},
            "vehicle_excesses": {},
            "driver_excesses": [],
            "compulsory_excess": 0
          }
          this.premiums = cals;

        },);
  }

  /*
  * Get Peliwica Fee
  */
  peliwica_fee: number = 0;
  getPeliwicaPremium() {
    this.quoteService.getLiveStockBrokerCommission(this.sessionSt.retrieve('userInfo').company_id, environment.AMLINE_ID)
      .subscribe(
        (response: any) => {
          if (response.success) {
            if (this.modelObj.type == 3) {
              this.peliwica_fee = 0;
              this.frmFields.get('peliwica_fee').setValue(0);
            } else {
              this.peliwica_fee = response.result.broker_admin_fee;
              this.frmFields.get('peliwica_fee').setValue(response.result.broker_admin_fee);
            }
          }
          this.calculatePremium();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  /*
  * Bind Breakdown Selection
  */
  onBreakdownSelection(event, vehicle_number) {
    this.modelObj.vehicles.forEach((res) => {
      if (res.registration_number == vehicle_number) {
        res.breakdown_cover = event.value;
      }
    })
    this.calculatePremium();
  }

  /*
  * Commission Restrict to 20
  */
  onCommissionChange(event) {
    if (Number(event.target.value) > 20) {
      this.frmFields.get('commission').setValue(20);
    }
    this.calculatePremium();
  }
  /*
  * Broker Commission change modal value
  */
  onBrokerCommissionChange(event) {
    this.modelObj.broker_admin_fee = event.target.value;
    this.calculatePremium();
  }
  /*
  * Discount Restrict to 10
  */
  onDiscountChange(event) {
    if (Number(event.target.value) > 10) {
      this.frmFields.get('discount').setValue(10);
    }
    if (Number(event.target.value) < 0) {
      this.frmFields.get('discount').setValue(0);
    }
    this.calculatePremium();
  }

  /*
  * Bind Owner Name
  */
  onOwnerNameChange(event, vehicle_id) {
    this.modelObj.vehicles.forEach((res) => {
      if (res.id == vehicle_id) {
        res.owner_name = event.target.value;
      }
    })
  }

  /*
  * Save Quote Details
  */
  onSubmit(content, referral) {
    this.submitted = true;
    if (!this.frmFields.valid) {
      return false;
    }

    if (this.hasErrors) {
      return false;
    }

    if (this.modelObj.type == 2) {
      this.modelObj.policy_id = null;
      this.modelObj.effective_date = null;
    }

    if (this.isReferred) {
      this.modalService.open(referral, { centered: true });
    } else {
      this.saveQuote(content);
    }
  }

  bindPostData() {
    let formData = this.frmFields.value;
    this.modelObj.voluntary_excess = formData.voluntary_excess;
    this.modelObj.commission = 20; //formData.commission;
    this.modelObj.discount = (formData.discount > 0) ? formData.discount : 0;
    this.modelObj.peliwica_fee = formData.peliwica_fee;
  }

  saveQuote(content) {
    let obj;
    if (this.modelObj.amlin_data) {
      obj = this.modelObj.amlin_data.data.attributes.application;
    } else {
      obj = this.modelObj;
    }

    let formData = this.frmFields.value;
    obj.voluntary_excess = formData.voluntary_excess;
    obj.commission = formData.commission;
    obj.broker_admin_fee = formData.broker_admin_fee;
    obj.discount = formData.discount;
    obj.peliwica_fee = formData.peliwica_fee;

    let data = {
      data: {
        "type": this.modelObj.type,
        "attributes": obj,
        "id": this.modelObj.id,
        "sequence_number": this.modelObj.sequence_number,
        // "id": (this.route.snapshot.params.id) ? this.route.snapshot.params.id : this.modelObj.id,
        "broker_id": this.modelObj.broker_id,
      },
      meta: {
        "refer": true
      },

    };


    this.as.saveQuote(data)
      .subscribe(
        (res: any) => {
          if (res.success) {
            this.modalService.open(content, { centered: true });
            this.helptext = 'Data Saved.';
            this.modelObjUpdated.emit(this.modelObj);
            this.router.navigateByUrl("/amline/quote-details/" + res.result.quote_data.quote_versions[0].amlin_id);
          } else {
            this._snackBar.open(res.message, '', {
              duration: 5000,
              panelClass: 'text-danger'
            });
          };
          this.modalService.dismissAll();
        });
  }

}
